import Vue from "vue"
import moment from "moment"

Vue.filter("formatPhone", function (v) {
  if (!v) { return "" }
  const s2 = ("" + v).replace(/\D/g, "")
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3]
})

Vue.filter("formatUnixDate", function (v) {
  if (!v) { return "" }
  return moment.unix(v).format("MMM DD, YYYY")
})

Vue.filter("formatDate", function (value) {
  if (!value) { return "" }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD, YYYY")
})

Vue.filter("formatDateTime", function (value) {
  if (!value) { return "" }
  // example of how to get local date/time working:
  const date = moment.utc(value).toDate()
  const local = moment(date)
    .local()
    .format("MMM DD, YYYY h:mm A")
  return local
})
Vue.filter("formatSimpleDateTime", function (value) {
  if (!value) { return "" }
  // example of how to get local date/time working:
  const date = moment.utc(value).toDate()
  const local = moment(date)
    .local()
    .format("MMM DD, YYYY hh:mm")
  return local
})

Vue.filter("formatNotification", function (value) {
  return value
})

Vue.filter("formatLocalDate", function (value) {
  if (!value) { return "" }
  const date = new Date(value)
  return moment
    .utc(date)
    .local()
    .format("MMM DD, YYYY")
})
Vue.filter("formatMoney", function (value) {
  if (!value) {
    return "---"
  } else {
    return (
      "$" + (value / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    )
  }
})

Vue.filter("formatMoneyRaw", function (value) {
  if (!value) {
    return "---"
  } else {
    return "$" + value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
})
Vue.filter("price", function (value) {
  if (!value) {
    return ""
  } else {
    value = value.slice(0, -3)
    return "$" + value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
})
Vue.filter("pluralize", function (n, w) {
  if (n === 1) {
    return w
  } else {
    return w + "s"
  }
})
Vue.filter("titleCase", function (value) {
  let str = value.toLowerCase()
  str = str.split(" ")

  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(" ")
})

Vue.filter("formatTime", function (value) {
  // return moment(value).format("h:mm A");
  return moment(value, ["h:mm A"]).format("h:mm A")
})

Vue.filter("timeAgo", function (value) {
  function pluralize (n, w) {
    if (n === 1) {
      return w
    } else {
      return w + "s"
    }
  }
  const between = Date.now() / 1000 - Number(value)
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute")
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour")
  } else {
    return pluralize(~~(between / 86400), " day")
  }
})

// export function timeAgo(time) {
// }
