//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "../../assets/icons/star"

export default {
  components: {},
  props: {
    opts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      hide: false,
      ready: false,
      options: {}
    }
  },
  computed: {
    linkType () {
      if (this.domainPageId) {
        return "href"
      }
      return "to"
    },
    imageCenterOnMobile () {
      if (this.$vuetify.breakpoint.smAndUp) {
        return ""
      }
      return "left:-24px;"
    },
    uuid () {
      return localStorage.getItem("uuid")
    },
    domainPageId () {
      return this.$store.state.legacypages.domainPageId
    },
    allPages () {
      return this.$store.state.pages.all
    },
    propertySearch () {
      let propertySearch = false
      if (this.objHas(this.activePage, "property_search")) {
        propertySearch = this.activePage.property_search
      }
      return propertySearch
    },
    favoritesCount () {
      let count = this.$store.state.system.favorites.length
      if (!count) {
        count = "0"
      }
      return count
    },
    leftMenuShow: {
      get () {
        return this.$store.state.menus.leftMenuShow
      },
      set (value) {
        this.$store.commit("menus/set_leftMenuShow", value)
      }
    },
    rightMenuShow: {
      get () {
        return this.$store.state.menus.rightMenuShow
      },
      set (value) {
        this.$store.commit("menus/set_rightMenuShow", value)
      }
    },
    activeUser () {
      return this.$store.state.system.user
    }
  },
  watch: {
    activePage () {
      this.ready = false
      if (!this.isEmptyObject(this.activePage)) {
        // console.log("from watch")
        this.pageLoaded()
      }
    }
  },
  mounted () {
    this.ready = false
    if (!this.isEmptyObject(this.activePage)) {
      // console.log("from mount")
      this.pageLoaded()
    }
  },
  methods: {
    getRoute (route) {
      if (this.domainPageId) {
        return this.BASE_URL + route
      }
      return route
    },
    pageLoaded () {
      // console.log("page defined")
      let opts = {}
      if (this.propertySearch) {
        opts = {
          app: true,
          prominent: true,
          elevation: "0",
          fixed: true,
          "clipped-left": true,
          "clipped-right": true,
          "shrink-on-scroll": false
        }
      } else {
        opts = {
          app: true,
          "hide-on-scroll": this.$vuetify.breakpoint.mdAndUp,
          elevation: "0",
          fixed: true,
          "clipped-left": true,
          "clipped-right": true
        }
      }
      this.options = opts
      this.ready = true
    }
  }
}
