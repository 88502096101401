// State ======================
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  pageLayout: "",
  type: "frontagents",
  relations: [],
  all: {},
  reference: {},
  active: false,
  page: 1,
  filter: {},
  pageCount: 0,
  print_path: "",
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "id",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  stub: {},
  bound: {},
  include: [],
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
