//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      minPrice: "",
      maxPrice: "",
      dialog: false,
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 0
      },
      priceRanges: [
        { display: "100,000", value: 100000 },
        { display: "200,000", value: 200000 },
        { display: "300,000", value: 300000 },
        { display: "400,000", value: 400000 },
        { display: "500,000", value: 500000 },
        { display: "600,000", value: 600000 },
        { display: "700,000", value: 700000 },
        { display: "800,000", value: 800000 },
        { display: "900,000", value: 900000 },
        { display: "1M", value: 1000000 },
        { display: "1.25M", value: 1250000 },
        { display: "1.5M", value: 1500000 },
        { display: "1.75M", value: 1750000 },
        { display: "2M", value: 2000000 },
        { display: "2.25M", value: 2250000 },
        { display: "2.5M", value: 2500000 },
        { display: "2.75M", value: 2750000 },
        { display: "3M", value: 3000000 },
        { display: "3.5M", value: 3500000 },
        { display: "4M", value: 4000000 },
        { display: "4.5M", value: 4500000 },
        { display: "5M", value: 5000000 },
        { display: "6M", value: 6000000 },
        { display: "7M", value: 7000000 },
        { display: "8M", value: 8000000 },
        { display: "9M", value: 9000000 },
        { display: "10M", value: 10000000 }
      ]
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    },
    buttonLabel () {
      let maxPrice = 0
      let minPrice = 0
      if (this.objHas(this.$route.query, "price_min")) {
        minPrice = this.$route.query.price_min
      }
      if (this.objHas(this.$route.query, "price_max")) {
        maxPrice = this.$route.query.price_max
      }

      if (maxPrice && minPrice) {
        return (
          "$" +
          this.nFormatter(minPrice, 2) +
          "-$" +
          this.nFormatter(maxPrice, 2)
        )
      }
      if (maxPrice && !minPrice) {
        return "Up to $" + this.nFormatter(maxPrice, 2)
      }
      if (!this.maxPrice && this.minPrice) {
        return "$" + this.nFormatter(minPrice, 2) + "+"
      }
      return "Price"
    },
    minPrices () {
      let minPrice = 0
      if (this.minPrice) {
        minPrice = this.minPrice
      }
      let priceRanges = []
      priceRanges = this.priceRanges.filter((range) => {
        if (range.value >= minPrice) {
          return range
        }
        return false
      })
      return priceRanges.slice(0, 7)
    },
    maxPrices () {
      let minPrice = 0
      if (this.minPrice) {
        minPrice = this.minPrice
      }
      let priceRanges = []
      priceRanges = this.priceRanges.filter((range) => {
        if (range.value >= minPrice) {
          return range
        }
        return false
      })
      return priceRanges.slice(1, 8)
    }
  },
  watch: {
    dialog () {
      this.priceReset()
    }
  },
  methods: {
    clearFilter () {
      this.minPrice = ""
      this.maxPrice = ""
      this.updateQueryString()
    },
    priceReset () {
      if (this.objHas(this.$route.query, "price_min")) {
        this.minPrice = this.$route.query.price_min
      } else {
        this.minPrice = ""
      }
      if (this.objHas(this.$route.query, "price_max")) {
        this.maxPrice = this.$route.query.price_max
      } else {
        this.maxPrice = ""
      }
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.price_min = this.minPrice
      currentQuery.price_max = this.maxPrice
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    },
    setMinPrice (price) {
      this.minPrice = price
      // this.updateQueryString()
    },
    setMaxPrice (price) {
      this.maxPrice = price
      // this.updateQueryString()
    }
  }
}
