export const state = () => ({
  snack: "",
  uuid: "",
  user: false,
  favorites: [],
  snackSettings: {
    color: "secondary",
    top: false,
    timeout: 4000,
    right: true
  },
  activeView: false,
  pastView: [],
  validateFields: {},
  showFormErrors: false,
  waterYear: new Date().getFullYear().toString(),
  availableWaterYears: [],
  customComponent: null
})
export const actions = {
  resetPassword ({ commit, state }, payload) {
    return this.$repositories.forgotPassword.create(payload)
  },

  active_view ({ commit, state }, payload) {
    if (Object.prototype.hasOwnProperty.call(payload, "customComponent")) {
      commit("SET_CUSTOM_COMPONENT", payload.customComponent)
    } else {
      commit("SET_CUSTOM_COMPONENT", null)
    }

    commit("SET_ACTIVE_VIEW", payload.view)
    commit("SET_HISTORY", payload)
  }
  // async navBack ({ commit, state, getters, dispatch, rootState }) {
  //   const new_active = state.pastView[1]
  //   await dispatch(`${new_active.view}/set_active`, new_active.id, {
  //     root: true
  //   })
  //   await commit("NAVIGATE_ACTIVE_DIALOG_BACK")
  // }
}
export const mutations = {
  NAVIGATE_ACTIVE_DIALOG_BACK (state) {
    state.pastView.splice(0, 2)
  },

  SET_FAVORITES (state, data) {
    state.favorites = data
  },

  SET_USER (state, data) {
    state.user = data
  },

  SET_UUID (state, data) {
    state.uuid = data
  },

  SET_CUSTOM_COMPONENT (state, componentName) {
    state.customComponent = componentName
  },

  SET_ACTIVE_VIEW (state, active) {
    state.activeView = active
  },

  SET_HISTORY (state, payload) {
    state.pastView.unshift(payload)
  },

  FLUSH_HISTORY (state) {
    state.pastView = []
  },

  SET_SNACK (state, snack) {
    state.snack = snack
  },

  set_snackSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },

  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields
  },

  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors
  },

  SET_ALL_WATER_YEARS (state, payload) {
    // console.log(Object.values(payload))
    state.availableWaterYears = Object.values(payload)
  },

  SET_WATER_YEAR (state, payload) {
    state.waterYear = payload
  }
}
export const getters = {
  permissions (state, commit, rootState) {
    let permissions = []
    permissions = rootState.auth.user.data.can
    return permissions
  },
  roles (state, commit, rootState) {
    let roles = []
    if (rootState.auth.user.data) {
      roles = rootState.auth.user.data.roles
    }
    return roles
  },
  getWaterYear (state) {
    return state.waterYear
  },
  getAllWaterYears (state) {
    return state.availableWaterYears
  }
}
