//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      propertyTypes: [],
      clearwaterProperties: false,
      sportsAfield: false
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    },
    buttonLabel () {
      let clearwaterProperties = false
      let propertyTypes = []
      if (this.objHasAndNotEmpty(this.$route.query, "exclusive")) {
        clearwaterProperties = this.$route.query.exclusive === "true"
      }
      let sportsAfield = false
      if (this.objHasAndNotEmpty(this.$route.query, "sportsafield")) {
        sportsAfield = this.$route.query.sportsafield === "true"
      }
      if (clearwaterProperties) {
        propertyTypes.push("Clearwater Exclusive")
      }
      if (sportsAfield) {
        propertyTypes.push("Sports Afield")
      }
      if (this.objHasAndNotEmpty(this.$route.query, "propertytypes")) {
        propertyTypes = propertyTypes.concat(
          this.$route.query.propertytypes.split(",")
        )
      }
      if (propertyTypes.length > 0) {
        if (propertyTypes.length > 1) {
          return propertyTypes[0] + " +" + (propertyTypes.length - 1)
        }
        return propertyTypes[0]
      } else {
        return "Property Types"
      }
    }
  },
  watch: {
    dialog () {
      this.typesReset()
    }
  },
  mounted () {
    // this.clearFilter()
  },
  methods: {
    typesReset () {
      if (this.objHasAndNotEmpty(this.$route.query, "exclusive")) {
        this.clearwaterProperties = this.$route.query.exclusive === "true"
      }
      if (this.objHasAndNotEmpty(this.$route.query, "sportsafield")) {
        this.sportsAfield = this.$route.query.sportsafield === "true"
      }
      if (this.objHasAndNotEmpty(this.$route.query, "propertytypes")) {
        this.propertyTypes = this.$route.query.propertytypes.split(",")
      } else {
        this.propertyTypes = []
      }
    },
    clearFilter () {
      this.propertyTypes = []
      this.clearwaterProperties = false
      this.sportsAfield = false
      // Object.values(this.apiPropertyTypes).forEach((type) => {
      // this.propertyTypes.push(type.name)
      // })
      this.updateQueryString()
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.propertytypes = this.propertyTypes.join(",")
      currentQuery.exclusive = this.clearwaterProperties
      currentQuery.sportsafield = this.sportsAfield
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    }
  }
}
