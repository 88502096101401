//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      locationOnEnter: false,
      dialog: false,
      location: "",
      locationObject: {},
      radius: "",
      exact: "exact",
      zip: ""
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    },
    buttonLabel () {
      let label = "Location: Any"
      let location = ""
      if (this.objHasAndNotEmpty(this.$route.query, "zip")) {
        location = "Zip: " + this.$route.query.zip
      }
      if (this.objHasAndNotEmpty(this.$route.query, "address")) {
        location = this.$route.query.address + ", "
      }

      if (this.objHasAndNotEmpty(this.$route.query, "city")) {
        location = location + this.$route.query.city + ", "
      }

      if (this.objHasAndNotEmpty(this.$route.query, "state")) {
        location = location + this.$route.query.state
      }

      if (location) {
        label = location
        if (this.objHasAndNotEmpty(this.$route.query, "radius")) {
          label = label + ", " + this.$route.query.radius + " miles"
        }
      }

      return label
    }
  },
  watch: {
    dialog () {
      this.locationReset()
    }
  },
  mounted () {
    this.locationReset()
  },
  methods: {
    onKeyPress (event) {
      if (event.key === "Enter") {
        event.preventDefault() // Prevent the default Enter key behavior

        // Simulate the down arrow key press to select the first suggestion
        this.simulateArrowDown()
      }
    },
    simulateArrowDown () {
      const inputElement = document.getElementById("map")
      const downArrowEvent = new KeyboardEvent("keydown", {
        key: "ArrowDown",
        code: "ArrowDown",
        keyCode: 40, // keyCode for down arrow
        bubbles: true
      })
      inputElement.dispatchEvent(downArrowEvent)

      // Simulate the enter key press
      const enterEvent = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13, // keyCode for enter
        bubbles: true
      })
      inputElement.dispatchEvent(enterEvent)
      // this.locationOnEnter = true
    },
    getAddressData (location) {
      if (this.objHas(location, "photos")) {
        delete location.photos
      }
      console.log("location")
      console.log(location)
      this.locationObject = location
    },
    locationReset () {
      let location = ""
      if (this.objHasAndNotEmpty(this.$route.query, "address")) {
        location = this.$route.query.address + ", "
      }

      if (this.objHasAndNotEmpty(this.$route.query, "city")) {
        location = location + this.$route.query.city + ", "
      }

      if (this.objHasAndNotEmpty(this.$route.query, "state")) {
        location = location + this.$route.query.state
      }

      this.location = location

      if (this.objHas(this.$route.query, "radius")) {
        this.radius = this.$route.query.radius
      } else {
        this.radius = ""
      }
      if (!this.radius) {
        this.exact = "exact"
      } else {
        this.exact = "radius"
      }
      if (this.$route.query?.zip) {
        this.zip = this.$route.query.zip
        this.exact = "zip"
      } else {
        this.zip = ""
      }
    },
    clearFilter () {
      this.location = ""
      this.radius = ""
      this.zip = ""
      this.exact = "exact"
      this.updateQueryString()
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)

      if (this.exact === "zip") {
        currentQuery.zip = this.zip
        currentQuery.value = this.value
        currentQuery.state = ""
        currentQuery.city = ""
        currentQuery.address = ""
        currentQuery.lat = ""
        currentQuery.long = ""
        currentQuery.radius = ""
      } else {
        if (this.location) {
          if (this.objHas(this.locationObject, "administrative_area_level_1")) {
            currentQuery.state = this.locationObject.administrative_area_level_1
            currentQuery.city = ""
            currentQuery.address = ""
            currentQuery.zip = ""
            currentQuery.lat = this.locationObject.latitude
            currentQuery.long = this.locationObject.longitude

            if (this.objHas(this.locationObject, "administrative_area_level_2")) {
              currentQuery.city = this.locationObject.locality
              if (this.objHas(this.locationObject, "street_number")) {
                currentQuery.address =
              this.locationObject.street_number +
              " " +
              this.locationObject.route
              }
            }
          }
        } else {
          currentQuery.zip = ""
          currentQuery.state = ""
          currentQuery.city = ""
          currentQuery.address = ""
          currentQuery.lat = ""
          currentQuery.long = ""
        }

        if (this.exact === "exact" || !this.location) {
          currentQuery.radius = ""
        } else {
          currentQuery.radius = this.radius
        }
      }

      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    }
  }
}
