import Vue from "vue"
import axios from "axios"
import { normalize } from "normalizr"
// import VueClipboard from "vue-clipboard2";

// import VuetifyDaterangePicker from "vuetify-daterange-picker";
// import "vuetify-daterange-picker/dist/vuetify-daterange-picker.css";

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    data () {
      return {
        cmsData: {}
      }
    },
    computed: {
      globalLoaded: {
        get () {
          return this.$store.state.frontproperties.loaded
        },
        set (value) {
          this.$store.commit("frontproperties/set_loaded", value)
        }
      },
      errorPage () {
        return this.$store.state.pages.errorPage
      },
      MEDIA_ENDPOINT () {
        const path = `${process.env.MEDIA_ENDPOINT}`
        return path
      },
      BASE_URL () {
        const path = `${process.env.BASE_URL}`
        return path
      },
      LARAVEL_ENDPOINT () {
        const path = `${process.env.LARAVEL_ENDPOINT}`
        return path
      },
      uuid () {
        return this.$store.state.system.uuid
      },
      activePage () {
        return this.$store.getters["pages/active"]
      },
      apiPropertyTypes () {
        return this.$store.state.propertytypes.all
      }
    },
    methods: {
      async getCmsData () {
        const queryOptions = this.$route.query
        let queryString = "?site=" + process.env.CMS_SITE_NAME
        let res = null
        if (this.objHasAndNotEmpty(queryOptions, "preview")) {
          queryString += "&preview=1"
          res = await axios(
            process.env.CMS_ENDPOINT + "/site-pages/" + queryString
          )
        } else {
          res = await axios(
            "https://cf-json.edge-marketing.workers.dev/sitedata?site=clearwaterproperties.com"
          )
        }
        const ids = res.data.data.map(item => item.id)
        const page = 1

        const { entities } = normalize(res.data.data, [
          this.$relationships.pages
        ])
        this.$store.commit("pages/REPLACE", entities.pages)
        const obj = {
          page,
          ids
        }
        this.$store.dispatch("pages/set_reference", obj)
        return true
      },
      generatePropertiesSearchParams (searchObj) {
        const search = { exact: {}, rootLevel: {} }

        if (this.objHasAndNotEmpty(searchObj, "extra_info")) {
          search.rootLevel.extra_info = searchObj.extra_info
        }

        // Start price query
        const priceBetween = []
        if (this.objHasAndNotEmpty(searchObj, "price_min")) {
          priceBetween.push(searchObj.price_min)
        } else {
          priceBetween.push(0)
        }

        if (this.objHasAndNotEmpty(searchObj, "price_max")) {
          priceBetween.push(searchObj.price_max)
        } else {
          priceBetween.push(0)
        }

        if (priceBetween.length > 0) {
          search.price_between = priceBetween
        }
        // end price query

        // Start acre query
        const acresBetween = []
        if (this.objHasAndNotEmpty(searchObj, "acre_min")) {
          acresBetween.push(searchObj.acre_min)
        } else {
          acresBetween.push(0)
        }

        if (this.objHasAndNotEmpty(searchObj, "acre_max")) {
          acresBetween.push(searchObj.acre_max)
        } else {
          acresBetween.push(0)
        }

        if (acresBetween.length > 0) {
          search.acres_between = acresBetween
        }
        // end acre query

        if (this.objHasAndNotEmpty(searchObj, "beds")) {
          search.bedrooms_min = searchObj.beds
        }

        if (this.objHasAndNotEmpty(searchObj, "mls")) {
          search.mls = [searchObj.mls]
        }

        if (this.objHasAndNotEmpty(searchObj, "agents")) {
          search.agent = searchObj.agents.split(",")
        }

        if (this.objHasAndNotEmpty(searchObj, "exclusive")) {
          if (searchObj.exclusive === "true") {
            search.exclusive = [1]
          }
        }

        if (this.objHasAndNotEmpty(searchObj, "sportsafield")) {
          if (searchObj.sportsafield === "true") {
            search.exact["listing.ptags.name"] = ["Sports Afield properties"]
          }
        }

        if (this.objHasAndNotEmpty(searchObj, "zip")) {
          search.exact.zip_code = searchObj.zip
        }

        if (this.objHasAndNotEmpty(searchObj, "baths")) {
          search.bathrooms_min = searchObj.baths
        }
        const geo = []
        if (this.objHasAndNotEmpty(searchObj, "radius")) {
          geo.push(searchObj.lat)
          geo.push(searchObj.long)
          geo.push(searchObj.radius)
          search.geo_search = geo
        } else if (this.objHasAndNotEmpty(searchObj, "address")) {
          geo.push(searchObj.lat)
          geo.push(searchObj.long)
          geo.push(".25")
          search.geo_search = geo
        } else {
          if (this.objHasAndNotEmpty(searchObj, "city")) {
            search.exact.city = searchObj.city
          }

          if (this.objHasAndNotEmpty(searchObj, "state")) {
            search.exact.state = searchObj.state
          }
        }

        if (this.objHasAndNotEmpty(searchObj, "propertytypes")) {
          const apiPropertyTypes = Object.values(this.apiPropertyTypes)
          const propertyTypes = searchObj.propertytypes.split(",")
          const selectedTypes = []
          propertyTypes.forEach((propertyType) => {
            selectedTypes.push(
              apiPropertyTypes.find(type => type.name === propertyType).value
            )
          })

          search.exact.property_type = selectedTypes.join(",")
        }

        return search
      },
      numberWithCommas (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      nFormatter (num, digits) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        const item = lookup
          .slice()
          .reverse()
          .find(function (item) {
            return num >= item.value
          })
        return item
          ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
          : "0"
      },
      getHead (
        title = "",
        description = "",
        image = this.BASE_URL + "/image/static/logo.jpg"
      ) {
        let head = {}

        if (!title) {
          if (this.objHasAndNotEmpty(this.activePage, "title")) {
            title = this.activePage.meta.title
          }
        }

        if (!description) {
          if (this.objHasAndNotEmpty(this.activePage, "meta")) {
            description = this.activePage.meta.description
          }
        }
        const routeSegments = this.$nuxt.$route.path.split("/")
        let pageId = this.activePage.id
        if (this.activePage.id === "tags-list-page") {
          pageId = routeSegments[1]
        }
        if (this.activePage.id === "home") {
          pageId = ""
        }
        head = {
          title,
          link: [
            {
              hid: "canonical",
              rel: "canonical",
              href: this.BASE_URL + "/" + pageId
            }
          ],
          meta: [
            {
              hid: "og:title",
              property: "og:title",
              content: title + " | Clearwater Properties"
            },
            {
              hid: "og:type",
              property: "og:type",
              content: "website"
            },
            {
              hid: "og:url",
              property: "og:url",
              content: this.BASE_URL + this.$nuxt.$route.path
            },
            {
              hid: "og:image",
              property: "og:image",
              content: image
            },
            {
              hid: "og:desc",
              property: "og:description",
              content: description
            },
            {
              hid: "t-type",
              property: "twitter:card",
              content: "summary_large_image"
            },
            {
              hid: "t-type",
              property: "twitter:title",
              content: title + " | Clearwater Properties"
            },
            {
              hid: "t-type",
              property: "twitter:description",
              content: description
            },
            {
              hid: "t-type",
              property: "twitter:image",
              content: image
            },
            {
              hid: "description",
              name: "description",
              content: description
            }
          ]
        }
        return head
      },
      propertySearchHistory () {
        const path = this.BASE_URL + this.$route.fullPath
        const params = [
          {
            name: "search",
            value: this.$route.query,
            link: path
          }
        ]
        const history = {
          uuid: this.uuid,
          uaction_id: 2,
          params
        }
        this.setHistory(history)
      },
      pageView () {
        const path = this.BASE_URL + this.$route.fullPath
        const params = [
          {
            name: "Page View",
            value: path,
            link: path
          }
        ]
        const history = {
          uuid: this.uuid,
          uaction_id: 1,
          params
        }
        this.setHistory(history)
      },
      unsaveProperty (property) {
        const path = this.BASE_URL + this.$route.fullPath
        const params = [
          {
            name: "saved_property",
            value: property,
            link: path
          }
        ]
        const history = {
          uuid: this.uuid,
          uaction_id: 4,
          params
        }
        this.setHistory(history)
      },
      saveProperty (property) {
        const path = this.BASE_URL + this.$route.fullPath
        const params = [
          {
            name: "saved_property",
            value: property,
            link: path
          }
        ]
        const history = {
          uuid: this.uuid,
          uaction_id: 3,
          params
        }
        this.setHistory(history)
      },
      setHistory (history) {
        this.$axios
          .post(this.LARAVEL_ENDPOINT + "/api/front/history", history)
          .then((response) => {
            this.$store.commit("system/SET_FAVORITES", response.data.meta)
            // console.log("the meta")
            // console.log(response.data.meta)
          })
      },
      fieldValidateRules (field, v) {
        if (field.rules.required) {
          if (!v) {
            return field.label + " is required!"
          }
          if (field.type === "email") {
            if (!/.+@.+\..+/.test(v)) {
              return "Email must be valid"
            }
          }
        }
        return true
      },
      genClass (optKey) {
        let componentName = this.$options._componentTag
        componentName = componentName
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .toLowerCase()
        return "e-" + componentName + "-" + optKey
      },
      showErrorSnack (errorMessage) {
        this.$store.commit("system/SET_SNACK", "ERROR: " + errorMessage)
        const snackSettings = {
          color: "error",
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit("system/set_snackSettings", snackSettings)
      },
      showSuccessSnack (errorMessage) {
        this.$store.commit("system/SET_SNACK", "Success: " + errorMessage)
        const snackSettings = {
          color: "secondary",
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit("system/set_snackSettings", snackSettings)
      },
      isEmptyObject (obj) {
        return JSON.stringify(obj) === "{}"
      },
      cloneObj (obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      objHas (obj, field) {
        if (obj == null) {
          return false
        }
        if (typeof obj === "object") {
          return Object.prototype.hasOwnProperty.call(obj, field)
        }
        return false
      },
      objToArray (obj) {
        return Object.values(this.cloneObj(obj))
      },
      objHasAndNotEmpty (obj, field) {
        if (this.objHas(obj, field)) {
          if (obj[field]) {
            return true
          }
        }
        return false
      }
    }
  })
}

Vue.use(require("vue-moment"))
