//
//
//
//
//
//
//
//

export default {
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      map: false
    }
  },
  computed: {
    queryOptions () {
      return this.$route.query
    },
    queryMap () {
      const map = this.queryOptions.map === "true"
      return map
    },
    buttonIcon () {
      if (this.map) {
        return "mdi-format-list-text"
      }
      return "mdi-map"
    },
    buttonLabel () {
      if (this.map) {
        return "List"
      }
      return "Map"
    }
  },
  watch: {
    map () {
      this.updateQueryString()
    }
  },
  mounted () {
    this.map = this.queryMap
    this.updateQueryString()
  },
  methods: {
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.map = this.map
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      window.scrollTo(0, 0)
    }
  }
}
