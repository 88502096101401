//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    getBack () {
      if (this.$nuxt.context.from) {
        this.$router.back()
      } else {
        this.$router.push({
          path: "/"
        })
      }
    }
  }
}
