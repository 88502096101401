//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      baths: 0,
      beds: 0
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    },
    buttonLabel () {
      let beds = 0
      let baths = 0
      if (this.objHas(this.$route.query, "baths")) {
        baths = parseInt(this.$route.query.baths)
      }
      if (this.objHas(this.$route.query, "beds")) {
        beds = parseInt(this.$route.query.beds)
      }
      if (beds && baths) {
        return beds + "+ bd, " + baths + "+ ba"
      }
      if (!beds && baths) {
        return "Any bd, " + baths + "+ ba"
      }
      if (beds && !baths) {
        return beds + "+ bd, Any ba"
      }
      return "Beds & Baths"
    }
  },
  watch: {
    dialog () {
      this.bedBathsReset()
    }
  },
  mounted () {},
  methods: {
    bedBathsReset () {
      if (this.objHas(this.$route.query, "beds")) {
        this.beds = parseInt(this.$route.query.beds)
      } else {
        this.beds = 0
      }
      if (this.objHas(this.$route.query, "baths")) {
        this.baths = parseInt(this.$route.query.baths)
      } else {
        this.baths = 0
      }
    },
    clearFilter () {
      this.beds = 0
      this.baths = 0
      this.updateQueryString()
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.beds = this.beds
      currentQuery.baths = this.baths
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    }
  }
}
