export default ($axios, $store) => (resource, subresource) => ({
  index (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const apiUrl = process.env.LARAVEL_ENDPOINT
        const results = {}
        results.data = []
        const meta = {
          current_page: 1,
          from: 1,
          last_page: 1,
          path: `${apiUrl}/api/${resource}`,
          per_page: "150",
          to: 0,
          total: 0
        }
        results.error = error.response
        results.meta = meta
        return results
      })
  },
  subIndex (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const apiUrl = process.env.LARAVEL_ENDPOINT
        const results = {}
        results.data = []
        const meta = {
          current_page: 1,
          from: 1,
          last_page: 1,
          path: `${apiUrl}/api/${resource}`,
          per_page: "150",
          to: 0,
          total: 0
        }
        results.error = error.response
        results.meta = meta
        return results
      })
  },
  show (id) {
    return $axios
      .$get(`api/${resource}/${id}`)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response

        return results
      })
  },
  create (payload) {
    return $axios
      .$post(`api/${resource}`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  execute (id) {
    return $axios
      .$post(`api/${resource}/${id}/execute`)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },

  // TODO: Do we need this or can we use create?
  createFile (payload) {
    return $axios
      .$post(`api/${resource}/upload`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },

  // TODO:: Delete this
  createFileUpload (payload) {
    return $axios
      .$post(`api/${resource}/upload`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subCreate (parentId, payload) {
    // need to show error in snackbar ... this is called threw ApiModel
    return $axios
      .$post(`api/${resource}/${parentId}/${subresource}`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subCreateFile (parentId, payload, subresourceOverride = "") {
    let newSubresource = subresource
    if (subresourceOverride) {
      newSubresource = subresourceOverride
    }
    return $axios
      .$post(`api/${resource}/${parentId}/${newSubresource}`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  update ({ id, payload }) {
    return $axios
      .$put(`api/${resource}/${id}`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subUpdate (parentId, childId, payload) {
    return $axios
      .$put(`api/${resource}/${parentId}/${subresource}/${childId}`, payload)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  delete (id) {
    return $axios
      .$delete(`api/${resource}/${id}`)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subDelete (parentId, childId) {
    return $axios
      .$delete(`api/${resource}/${parentId}/${subresource}/${childId}`)
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  access (x) {
    return $axios
      .$post(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  print (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subPrint (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  showPrint (id, x) {
    return $axios
      .$get(`api/${resource}/${id}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  // export
  export (x) {
    return $axios
      .$get(`api/${resource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  subExport (id, x) {
    return $axios
      .$get(`api/${resource}/${id}/${subresource}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  },
  showExport (id, x) {
    return $axios
      .$get(`api/${resource}/${id}`, { params: x })
      .then((results) => {
        return results
      })
      .catch((error) => {
        const results = {}
        results.data = {}
        results.error = error.response
        return results
      })
  }
})
