// State ======================
import axios from "axios"
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  pageLayout: "",
  type: "users",
  relations: [],
  all: {},
  reference: {},
  active: false,
  page: 1,
  filter: {},
  pageCount: 0,
  print_path: "",
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: null,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  stub: {},
  bound: {},
  include: [],
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  userRequest: (state) => {
    state.status = "loading"
  },
  userSuccess: (state, payload) => {
    // console.log(state)
    state.status = "success"
    state.user = payload.data
  },
  userError: (state, err) => {
    state.status = err
  }
}

// Actions ====================

export const actions = {
  ...globalActions,
  async userRequest ({ commit, dispatch }) {
    commit("userRequest")
    let response
    try {
      response = await axios.get("api/me")
    } catch (ex) {
      commit("userError", ex)
      dispatch("authLogout")
    }

    commit("userSuccess", response.data)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters,
  user: state => state.user,
  permissions: (state) => {
    return state.user.can
  }
}
