//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    plain: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      mls: "",
      dialog: false
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    }
  },
  watch: {},
  mounted () {},
  methods: {
    go () {
      if (this.plain) {
        this.$router.push({
          path: "/properties/?mls=" + this.mls
        })
      } else {
        const currentQuery = this.cloneObj(this.$route.query)
        currentQuery.mls = this.mls
        this.$router.push({
          path: this.$route.path,
          query: currentQuery
        })
      }

      this.dialog = false
    }
  }
}
