//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      fab: false
    }
  },
  computed: {
    bottomButtons () {
      if (!this.pages) {
        return [] // Return empty array if pages data is not loaded
      }
      const pages = Object.values(this.cloneObj(this.pages)).filter(
        page => page.menu.menus?.includes("Second") ?? false
      )
      console.log("test pages")
      // console.log(this.pages)
      console.log(pages)
      const bottomButtonsMenu = []
      pages.forEach((page) => {
        let link = "/"
        let linkType = "to"
        let target = ""
        if (page.id !== "home") {
          link = link + page.id
        }
        if (this.objHasAndNotEmpty(page.menu, "outside_link")) {
          link = page.menu.outside_link
          linkType = "href"
          target = "_blank"
        }
        if (this.objHasAndNotEmpty(page.menu, "link_override")) {
          link = page.menu.link_override
        }
        let order = 0
        const findOrder = page.menu.orders.find(m => m.menu === "Second")
        if (findOrder) {
          order = findOrder.order
        }
        bottomButtonsMenu.push({
          title: page.title,
          link,
          linkType,
          target,
          key: page.id,
          order
        })
      })
      bottomButtonsMenu.sort((a, b) => (a.order > b.order ? 1 : -1))
      return bottomButtonsMenu
    },
    menu () {
      if (!this.pages) {
        return [] // Return empty array if pages data is not loaded
      }
      const pages = this.cloneObj(this.pages)

      const menu = []

      const mainPages = Object.values(pages).filter(
        page => page.menu.menus?.includes("Main") ?? false
      )
      // console.log(mainPages)

      mainPages.forEach((page) => {
        if (!page.menu.parent_id) {
          const subPages = Object.values(pages).filter(
            subPage => subPage.menu.parent_id === page.id
          )
          const subMenu = []
          if (subPages.length > 0) {
            for (const [subKey, subPage] of Object.entries(subPages)) {
              let subLink = "/" + subPage.id
              let subLinkType = "to"
              let subTarget = ""
              if (this.objHasAndNotEmpty(subPage.menu, "link_override")) {
                subLink = subPage.menu.link_override
              }
              if (this.objHasAndNotEmpty(page.menu, "outside_link")) {
                subLink = subPage.menu.outside_link
                subLinkType = "href"
                subTarget = "_blank"
              }
              let order = 0
              const findOrder = subPage.menu.orders.find(m => m.menu.toLowerCase() === subPage.menu.parent_id)
              if (findOrder) {
                order = findOrder.order
              }
              subMenu.push({
                title: subPage.title,
                link: subLink,
                linkType: subLinkType,
                target: subTarget,
                key: subKey,
                order
              })
            }
          }
          subMenu.sort((a, b) => (a.order > b.order ? 1 : -1))
          let link = "/"
          let linkType = "to"
          let target = ""
          if (page.id !== "home") {
            link = link + page.id
          }
          if (this.objHasAndNotEmpty(page.menu, "link_override")) {
            link = page.menu.link_override
          }
          if (this.objHasAndNotEmpty(page.menu, "outside_link")) {
            link = page.menu.outside_link
            linkType = "href"
            target = "_blank"
          }
          let order = 0
          const findOrder = page.menu.orders.find(m => m.menu === "Main")
          if (findOrder) {
            order = findOrder.order
          }
          menu.push({
            title: page.title,
            link,
            linkType,
            target,
            items: subMenu,
            key: page.id,
            order
          })
        }
      })

      menu.sort((a, b) => (a.order > b.order ? 1 : -1))

      return menu
    },
    pages () {
      return this.$store.state.pages.all
    },
    leftMenuShow: {
      get () {
        return this.$store.state.menus.leftMenuShow
      },
      set (value) {
        this.$store.commit("menus/set_leftMenuShow", value)
      }
    }
  },
  mounted () {
    // console.log(this.pages)
  }
}
