//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      showDeepDialog: false
    }
  },
  async fetch () {
    await this.$store.dispatch("pages/listJSON")
    await this.getCmsData()
    await Promise.all([
      this.$store.dispatch("fronttestimonials/listJSON"),
      this.$store.dispatch("frontagents/listJSON"),
      this.$store.dispatch("offices/listJSON"),
      this.$store.dispatch("webcams/listJSON"),
      this.$store.dispatch("propertytypes/listJSON"),
      this.$store.dispatch("legacypages/listJSON"),
      this.$store.dispatch("ads/listJSON")
    ])
  },
  computed: {
    leftMenuShow: {
      get () {
        return this.$store.state.menus.leftMenuShow
      },
      set (value) {
        this.$store.commit("menus/set_leftMenuShow", value)
      }
    },
    domainPageId () {
      return this.$store.state.legacypages.domainPageId
    },
    pages () {
      return Object.values(this.$store.state.pages.all)
    }
  },
  watch: {
    $route () {
      this.leftMenuShow = false
      this.$store.commit("pages/SET_ERROR_PAGE", false)
      this.pageView()
    }
  },
  mounted () {
    this.$store.dispatch("fronttestimonials/list")
    this.$store.dispatch("frontagents/list")
    this.$store.dispatch("offices/list")
    this.$store.dispatch("webcams/list")
    this.$store.dispatch("propertytypes/list")
    this.$store.dispatch("legacypages/list")
    this.$store.dispatch("ads/list")
    let uuid = localStorage.getItem("uuid")
    if (!uuid) {
      uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
      localStorage.setItem("uuid", uuid)
    }
    this.$store.commit("system/SET_UUID", uuid)
    this.pageView()
    this.$nextTick(() => {
      if (!sessionStorage.getItem("deepDialogShown")) {
        if (this.$vuetify.breakpoint.name === "xs") {
          setTimeout(() => {
            this.showDeepDialog = true
            sessionStorage.setItem("deepDialogShown", "true") // Set the session variable
          }, 10000)
        }
      }
    })
  },
  auth: false
}
