import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4bddcc47 = () => interopDefault(import('../pages/agents/index.vue' /* webpackChunkName: "pages/agents/index" */))
const _ba7618e4 = () => interopDefault(import('../pages/billings.vue' /* webpackChunkName: "pages/billings" */))
const _776deec2 = () => interopDefault(import('../pages/cmp-admin.vue' /* webpackChunkName: "pages/cmp-admin" */))
const _2ca86e62 = () => interopDefault(import('../pages/market-report/index.vue' /* webpackChunkName: "pages/market-report/index" */))
const _71e13ab4 = () => interopDefault(import('../pages/offices/index.vue' /* webpackChunkName: "pages/offices/index" */))
const _84685ba8 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _78f5efd6 = () => interopDefault(import('../pages/webcams/index.vue' /* webpackChunkName: "pages/webcams/index" */))
const _47d07761 = () => interopDefault(import('../pages/agents/_agent.vue' /* webpackChunkName: "pages/agents/_agent" */))
const _2a74431c = () => interopDefault(import('../pages/favorites/_uuid.vue' /* webpackChunkName: "pages/favorites/_uuid" */))
const _169d4c6b = () => interopDefault(import('../pages/market-report/_market.vue' /* webpackChunkName: "pages/market-report/_market" */))
const _7225b6bd = () => interopDefault(import('../pages/offices/_office.vue' /* webpackChunkName: "pages/offices/_office" */))
const _04c99bae = () => interopDefault(import('../pages/resources/_link.vue' /* webpackChunkName: "pages/resources/_link" */))
const _182fbd9d = () => interopDefault(import('../pages/webcams/_webcam.vue' /* webpackChunkName: "pages/webcams/_webcam" */))
const _22e20ec0 = () => interopDefault(import('../pages/checkauth/_uuid/_accesstoken.vue' /* webpackChunkName: "pages/checkauth/_uuid/_accesstoken" */))
const _9e60a212 = () => interopDefault(import('../pages/resources/_category/_link.vue' /* webpackChunkName: "pages/resources/_category/_link" */))
const _39cf6e2b = () => interopDefault(import('../pages/properties/_property/_city/_address.vue' /* webpackChunkName: "pages/properties/_property/_city/_address" */))
const _22900cf2 = () => interopDefault(import('../pages/_link/_page.vue' /* webpackChunkName: "pages/_link/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agents",
    component: _4bddcc47,
    name: "agents"
  }, {
    path: "/billings",
    component: _ba7618e4,
    name: "billings"
  }, {
    path: "/cmp-admin",
    component: _776deec2,
    name: "cmp-admin"
  }, {
    path: "/market-report",
    component: _2ca86e62,
    name: "market-report"
  }, {
    path: "/offices",
    component: _71e13ab4,
    name: "offices"
  }, {
    path: "/properties",
    component: _84685ba8,
    name: "properties"
  }, {
    path: "/webcams",
    component: _78f5efd6,
    name: "webcams"
  }, {
    path: "/agents/:agent",
    component: _47d07761,
    name: "agents-agent"
  }, {
    path: "/favorites/:uuid?",
    component: _2a74431c,
    name: "favorites-uuid"
  }, {
    path: "/market-report/:market?",
    component: _169d4c6b,
    name: "market-report-market"
  }, {
    path: "/offices/:office",
    component: _7225b6bd,
    name: "offices-office"
  }, {
    path: "/resources/:link?",
    component: _04c99bae,
    name: "resources-link"
  }, {
    path: "/webcams/:webcam",
    component: _182fbd9d,
    name: "webcams-webcam"
  }, {
    path: "/checkauth/:uuid?/:accesstoken?",
    component: _22e20ec0,
    name: "checkauth-uuid-accesstoken"
  }, {
    path: "/resources/:category?/:link?",
    component: _9e60a212,
    name: "resources-category-link"
  }, {
    path: "/properties/:property/:city?/:address?",
    component: _39cf6e2b,
    name: "properties-property-city-address"
  }, {
    path: "/:link?/:page?",
    component: _22900cf2,
    name: "link-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
