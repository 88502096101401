// State ======================

export const state = () => ({
  leftMenuShow: false,
  rightMenuShow: false
})

// Mutations ==================

export const mutations = {
  set_leftMenuShow (state, payload) {
    state.leftMenuShow = payload
  },
  set_rightMenuShow (state, payload) {
    state.rightMenuShow = payload
  }
}

// Actions ====================

export const actions = {}

// Getters ====================

export const getters = {}
