import { parse } from "@babel/core";
import { denormalize, normalize } from "normalizr";

export function resolveRelations(data, fields, rootState) {
  let results = {};
  fields.forEach(field => {
    results[field.name] = [];
    if (Array.isArray(data[field.name])) {
      results[field.name] = data[field.name].map(
        x => rootState[field.value].all[x]
      );
    } else {
      results[field.name] = rootState[field.value].all[data[field.name]];
    }
  });
  return { ...data, ...results };
}

export function resolveSchema(data) {
  let results = {};

  results["id"] = data.id;
  results["section_name"] = data.section_name;
  results["schema"] = data.schema;
  return results;
}

export function resolveNotifications(data) {
  let results = {};
  results["id"] = data.id;
  results["type"] = data.type;
  results["schema"] = data.data;
  return results;
}
