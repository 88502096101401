
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.27l6.18 3.728-1.636-7.03L22 9.244l-7.19-.618-2.81-6.627L9.19 8.625 2 9.243l5.454 4.726-1.635 7.029L12 17.27z"/>'
  }
})
