//
//
//
//
//
//

import ErrorPage from "~/components/ErrorPage.vue"
export default {
  components: { ErrorPage },
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  created () {
    this.$store.commit("pages/SET_ERROR_PAGE", true)
  }
}
