/* eslint-disable */

export default ({ app }) => {
  /*
  ** Include Google Analytics Script
  */
  (function(w,d,s,l,i){
    w[l]=w[l]||[]; 
    w.gtag = function(){w[l].push(arguments);} // attach gtag to window object
    w.gtag('js', new Date());

    var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s);
    j.async=true;
    j.src='https://www.googletagmanager.com/gtag/js?id='+i;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','G-LXX545NXTN');

  /*
  ** Set the current page
  */
  app.router.afterEach((to, from) => {
    /*
    ** We tell Google Analytics to add a `pageview`
    */
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-LXX545NXTN', {
        'page_path': to.fullPath,
        'send_page_view': true
      });
      console.log('gtag is a function')
    } else {
      const checkExist = setInterval(function() {
        console.log('checking for gtag');
        if (typeof window.gtag === 'function') {
          clearInterval(checkExist);
          window.gtag('config', 'G-LXX545NXTN', {
            'page_path': to.fullPath,
            'send_page_view': true
          });
        }
      }, 100); // check every 100ms
    }
  });
}



