import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=60fea162&scoped=true&"
import script from "./Overlay.vue?vue&type=script&lang=js&"
export * from "./Overlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fea162",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VBtn,VOverlay})
