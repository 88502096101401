var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[(_vm.ready)?_c('v-app-bar',_vm._b({key:_vm.activePage.id,staticClass:"main-menu",staticStyle:{"z-index":"9999"},attrs:{"text":"","dense":!_vm.$vuetify.breakpoint.mdAndUp || _vm.propertySearch}},'v-app-bar',_vm.options,false),[_c('v-card',{staticClass:"ma-0 pa-0'",attrs:{"width":"100%","flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"child-flex flex-nowrap"},[_c('div',{staticClass:"left-side-menu"},[_c('v-toolbar',{attrs:{"dense":!_vm.$vuetify.breakpoint.mdAndUp || _vm.propertySearch,"text":""}},[(!_vm.domainPageId)?_c('v-btn',{class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'mt-1' : 'mt-0 ml-1',
                  'text-h6'
                ],attrs:{"icon":!_vm.$vuetify.breakpoint.mdAndUp,"text":"","plain":""},on:{"click":function($event){_vm.leftMenuShow = !_vm.leftMenuShow}}},[_c('v-icon',{class:[
                    _vm.propertySearch && !_vm.$vuetify.breakpoint.mdAndUp
                      ? 'mt-0'
                      : '',
                    'mr-md-2'
                  ],attrs:{"large":"","color":"primary"}},[_vm._v("\n                  mdi-menu\n                ")]),_vm._v(" "),_c('span',{class:[
                    _vm.propertySearch && !_vm.$vuetify.breakpoint.mdAndUp
                      ? 'mt-2'
                      : '',
                    'd-none d-md-flex'
                  ],staticStyle:{"letter-spacing":".1em !important"}},[_c('span',{staticClass:"grey--text text--darken-3"},[_vm._v("MENU")])])],1):_c('v-btn',{attrs:{"href":"https://www.clearwaterproperties.com","text":""}},[_c('v-icon',{staticClass:"mr-md-2",attrs:{"medium":"","color":"primary"}},[_vm._v("\n                  mdi-backburger\n                ")]),_vm._v("\n                CLEARWATER\n              ")],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{class:[_vm.propertySearch ? 'mt-1' : 'd-md-none', '']},[_c('NuxtLink',{attrs:{"to":"/"}},[_c('v-img',{style:(_vm.imageCenterOnMobile),attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '180' : '175',"max-height":_vm.$vuetify.breakpoint.mdAndUp ? '40' : '40',"src":"/image/static/type_logo.png"}})],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"mt-2 px-2 d-none d-md-flex  text-106 emphasis grey--text text--darken-1",staticStyle:{"letter-spacing":".1em !important"}},[_vm._v("\n                800.577.3013\n              ")])],1),_vm._v(" "),_c('div',{class:[
                !_vm.propertySearch && _vm.$vuetify.breakpoint.mdAndUp
                  ? 'white-texture'
                  : '',
                ''
              ]})],1),_vm._v(" "),_c('div',{staticClass:"right-side-menu d-none d-sm-flex"},[(_vm.activeUser)?_c('v-toolbar',{class:[
                !_vm.propertySearch ? '' : 'pt-2',
                'd-flex justify-space-around align-center px-2'
              ],attrs:{"dark":"","color":"secondary","dense":!_vm.$vuetify.breakpoint.mdAndUp || _vm.propertySearch}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',_vm._b({class:[
                  !_vm.propertySearch && _vm.$vuetify.breakpoint.mdAndUp
                    ? 'mt-2'
                    : '',
                  ''
                ],staticStyle:{"letter-spacing":".1em !important"},attrs:{"text":"","nuxt":""}},"v-btn",_vm._d({},[_vm.linkType,_vm.getRoute('/favorites/' + _vm.uuid)])),[_c('span',{staticClass:"vbold primary--text text-106 font-weight-regular"},[_vm._v("Welcome "+_vm._s(_vm.activeUser.first_name)+"!")]),_vm._v(" "),_c('span',{staticClass:"ml-2 emphasis primary--text tracking-xwide text-106 font-weight-regular mr-1",staticStyle:{"letter-spacing":".1em !important"}},[_vm._v("("+_vm._s(_vm.favoritesCount)+")")]),_vm._v(" "),_c('v-icon',{staticClass:"primary--text"},[_vm._v("\n                  mdi-heart\n                ")])],1)],1):_c('v-toolbar',{class:[
                !_vm.propertySearch ? '' : 'pt-2',
                'd-flex justify-space-around align-center px-2'
              ],attrs:{"dark":"","color":"secondary","dense":!_vm.$vuetify.breakpoint.mdAndUp || _vm.propertySearch}},[_c('v-btn',_vm._b({staticClass:"secondary",class:[
                  !_vm.propertySearch && _vm.$vuetify.breakpoint.mdAndUp
                    ? 'mt-2'
                    : '',
                  ''
                ],staticStyle:{"letter-spacing":".1em !important"},attrs:{"text":"","nuxt":""}},"v-btn",_vm._d({},[_vm.linkType,_vm.getRoute('/register/')])),[_c('span',{staticClass:"vbold primary--text text-106 font-weight-regular"},[_vm._v("Sign In / Register")])]),_vm._v(" "),_c('v-btn',_vm._b({class:[
                  !_vm.propertySearch && _vm.$vuetify.breakpoint.mdAndUp
                    ? 'mt-2'
                    : '',
                  ''
                ],attrs:{"nuxt":"","medium":"","light":"","text":""}},"v-btn",_vm._d({},[_vm.linkType,_vm.getRoute('/favorites/' + _vm.uuid)])),[_c('span',{staticClass:"emphasis primary--text tracking-xwide text-106 font-weight-regular mr-1",staticStyle:{"letter-spacing":".1em !important"}},[_vm._v("("+_vm._s(_vm.favoritesCount)+")")]),_vm._v(" "),_c('v-icon',{staticClass:"primary--text"},[_vm._v("\n                  mdi-heart\n                ")])],1)],1),_vm._v(" "),_c('div',{class:[
                !_vm.propertySearch && _vm.$vuetify.breakpoint.mdAndUp
                  ? 'green-texture'
                  : '',
                ''
              ]})],1)])],1),_vm._v(" "),(_vm.propertySearch)?_c('v-card',{attrs:{"flat":"","dark":"","color":"primary","width":"100%"}},[_c('search-top')],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }