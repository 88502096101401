//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VMenu, VDialog } from "vuetify/lib"
export default {
  components: {
    VMenu,
    VDialog
  },
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      minArce: "",
      maxAcre: "",
      dialog: false,
      options: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 0
      },
      priceRanges: [
        { display: "1", value: 1 },
        { display: "5", value: 5 },
        { display: "10", value: 10 },
        { display: "15", value: 15 },
        { display: "20", value: 20 },
        { display: "30", value: 30 },
        { display: "40", value: 40 },
        { display: "50", value: 50 },
        { display: "60", value: 60 },
        { display: "70", value: 70 },
        { display: "80", value: 80 },
        { display: "90", value: 90 },
        { display: "100", value: 100 },
        { display: "200", value: 200 },
        { display: "300", value: 300 },
        { display: "400", value: 400 },
        { display: "500", value: 500 },
        { display: "1,000", value: 1000 },
        { display: "1,500", value: 1500 },
        { display: "2,000", value: 2000 },
        { display: "2,500", value: 2500 },
        { display: "3,000", value: 3000 },
        { display: "3,500", value: 3500 },
        { display: "4,000", value: 4000 },
        { display: "4,500", value: 4500 },
        { display: "5,000", value: 5000 },
        { display: "6,000", value: 6000 },
        { display: "7,000", value: 7000 },
        { display: "8,000", value: 8000 },
        { display: "9,000", value: 9000 },
        { display: "10,000", value: 10000 },
        { display: "20,000", value: 20000 }
      ]
    }
  },
  computed: {
    compOptions () {
      if (this.topMenu) {
        return {
          bottom: true,
          "offset-y": true,
          "close-on-content-click": false
        }
      }
      return { fullscreen: true }
    },
    compType () {
      if (this.topMenu) {
        return "v-menu"
      }
      return "v-dialog"
    },
    buttonLabel () {
      let maxAcre = 0
      let minAcre = 0
      if (this.objHas(this.$route.query, "acre_min")) {
        minAcre = this.$route.query.acre_min
      }
      if (this.objHas(this.$route.query, "acre_max")) {
        maxAcre = this.$route.query.acre_max
      }

      if (maxAcre && minAcre) {
        return (
          this.nFormatter(minAcre, 2) +
          "-" +
          this.nFormatter(maxAcre, 2) +
          " Acres"
        )
      }
      if (maxAcre && !minAcre) {
        return "Up to " + this.nFormatter(maxAcre, 2) + " Acres"
      }
      if (!maxAcre && minAcre) {
        return this.nFormatter(minAcre, 2) + "+ Acres"
      }
      return "Acres"
    },
    minArces () {
      let minArce = 0
      if (this.minArce) {
        minArce = this.minArce
      }
      let priceRanges = []
      priceRanges = this.priceRanges.filter((range) => {
        if (range.value >= minArce) {
          return range
        }
        return false
      })
      return priceRanges.slice(0, 7)
    },
    maxAcres () {
      let minArce = 0
      if (this.minArce) {
        minArce = this.minArce
      }
      let priceRanges = []
      priceRanges = this.priceRanges.filter((range) => {
        if (range.value >= minArce) {
          return range
        }
        return false
      })
      return priceRanges.slice(1, 8)
    }
  },
  watch: {
    dialog () {
      this.acreReset()
    }
  },
  methods: {
    clearFilter () {
      this.minArce = ""
      this.maxAcre = ""
      this.updateQueryString()
    },
    acreReset () {
      if (this.objHas(this.$route.query, "acre_min")) {
        this.minArce = this.$route.query.acre_min
      } else {
        this.minArce = ""
      }
      if (this.objHas(this.$route.query, "acre_max")) {
        this.maxAcre = this.$route.query.acre_max
      } else {
        this.maxAcre = ""
      }
    },
    nFormatter (num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value
        })
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0"
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.acre_min = this.minArce
      currentQuery.acre_max = this.maxAcre
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    },
    setminArce (price) {
      this.minArce = price
      // this.updateQueryString()
    },
    setmaxAcre (price) {
      this.maxAcre = price
      // this.updateQueryString()
    }
  }
}
