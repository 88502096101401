//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    topMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      sort: "listing_price",
      sortOptions: {
        listing_price: "Highest Price",
        "-listing_price": "Lowest Price",
        "-list_date": "Newest Listing"
      }
    }
  },
  computed: {
    buttonLabel () {
      return "Sort: " + this.sortOptions[this.sort]
    }
  },
  watch: {
    dialog () {
      // this.sortReset()
    },
    sort () {
      this.updateQueryString()
    }
  },
  mounted () {
    this.sortReset()
  },
  methods: {
    sortReset () {
      if (this.objHas(this.$route.query, "sort")) {
        this.sort = this.$route.query.sort
      }
    },
    clearFilter () {
      this.updateQueryString()
    },
    updateQueryString () {
      const currentQuery = this.cloneObj(this.$route.query)
      currentQuery.sort = this.sort
      this.$router.push({
        path: this.$route.path,
        query: currentQuery
      })
      this.dialog = false
    }
  }
}
