import { resolveRelations } from "./Helpers"

export default {
  find: (state, getters, rootState) => (id) => {
    return state.all[id]
      ? resolveRelations(state.all[id], state.relations, rootState)
      : false
  },

  active (state, getters, rootState) {
    return state.active
      ? resolveRelations(state.all[state.active], state.relations, rootState)
      : false
  },
  allHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.used
    })
    return filtered
  },

  visibleHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used
    })
    return filtered
  },
  publicHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used && obj.protected
    })
    return filtered
  },
  tableHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.tableDisplay && obj.used
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  createHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      if (obj.hasOwnProperty("createDisplay")) {
        return obj.createDisplay && obj.used
      } else {
        return obj.tableDisplay && obj.used
      }
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  reportList (state) {},
  exportAttributes (state) {
    const relationships = state.relations.map(({ name }) => name)

    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.used && obj.createDisplay && !relationships.includes(obj.field)
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  filterAttributes (state) {
    const relationships = state.relations.map(({ name }) => name)
    // console.log({ relationships })
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.used && obj.filterDisplay && !relationships.includes(obj.field)
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  filterRelations (state) {
    const headers = Object.values(state.relations)
    const filtered = headers.filter(function (obj) {
      return (
        obj.name !== "payments" &&
        obj.name !== "photos" &&
        obj.name !== "tasks" &&
        obj.name !== "documents" &&
        obj.name !== "profile" &&
        obj.name !== "notes"
      )
    })
    return filtered.map(({ name }) => name)
  },
  exportRelations (state) {
    return state.relations.filter(function (obj) {
      return obj.export
    })
  },
  params (state) {
    const x = {}
    const { sortBy, descending } = state.pagination
    let sort = sortBy
    if (descending) {
      sort = "-" + sortBy
      x.sort = sort
    } else {
      x.sort = sort
    }

    x.page = state.pagination.page
    if (state.pagination.itemsPerPage) {
      x.limit = state.pagination.itemsPerPage
    }

    // Root level query strings
    if (state.hasOwnProperty("filter")) {
      if (state.filter.hasOwnProperty("rootLevel")) {
        const rootLevel = state.filter.rootLevel
        delete state.filter.rootLevel
        Object.entries(rootLevel).forEach(([key, value]) => {
          x[key] = value
        })
      }
    }

    if (state.search) {
      const searchTerm = "filter_scope[search]"
      x[searchTerm] = state.search
    }

    // used to add in filter relations
    const relationsToinclude = []

    const filter = state.filter
    for (const prop in filter) {
      if (prop === "exact") {
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const exact = `filter_exact[${key}]`
          const arr = []
          let formattedValue
          // console.log("type of")
          // console.log(typeof value)

          if (typeof value === "object") {
            value = Object.values(value)
          }

          if (value.isArray) {
            value.forEach((v) => {
              arr.push(encodeURIComponent(v))
            })
            formattedValue = arr.join(",")
          } else {
            formattedValue = value
            // console.log(formattedValue)
          }

          x[exact] = formattedValue
        })
      } else if (prop === "relations") {
        // add each of these relations to the include!
        const without = []
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const arr = []

          const filterLabel = state.relations.find((obj) => {
            return obj.name === key
          })
          relationsToinclude.push(filterLabel.translate)
          const exactRelation = `filter_exact[${filterLabel.translate}.id]`
          // const v = Object.values(value)
          for (const [k, val] of Object.entries(value)) {
            // console.log({ val })
            // console.log({ k })
            if (k === "with_rel") {
              const v = Object.values(val)
              v.forEach((item) => {
                arr.push(encodeURIComponent(item))
              })
              const formattedValue = arr.join(",")
              x[exactRelation] = formattedValue
            } else if (k === "without_rel") {
              const filterLabel = state.relations.find((obj) => {
                return obj.name === key
              })

              const formattedScope = filterLabel.translate
              without.push(encodeURIComponent(formattedScope))
              // console.log({ without })
              // x[scopeName] = [];
            } else {
              const filterRelation = state.relations.find((obj) => {
                return obj.name === key
              })
              // let scopeName = `filter_scope[${k}]`;
              Object.entries(val).forEach(([k, v]) => {
                //
                const exact = `filter_exact[${filterRelation.translate}.${k}]`
                const arr = []
                let formattedValue
                // console.log("type of")
                // console.log(typeof value)

                if (typeof v === "object") {
                  v = Object.values(v)
                }

                if (v.isArray) {
                  v.forEach((val) => {
                    arr.push(encodeURIComponent(val))
                  })
                  formattedValue = arr.join(",")
                } else {
                  formattedValue = v
                  // console.log(formattedValue)
                }

                x[exact] = formattedValue
              })
            }
          }
        })
        if (without.length) {
          const formattedWithoutRelation = without.join("|")
          x["filter_scope[without_rel]"] = formattedWithoutRelation
        }
      } else {
        const arr = []

        let formattedValue = ""
        const scopeKey = prop
        Object.entries(filter[prop]).forEach(([key, value]) => {
          arr.push(value)
        })
        const scope = `filter_scope[${scopeKey}]`
        // console.log({ arr })
        formattedValue = arr

        x[scope] = formattedValue
      }
    }
    if (state.include.length) {
      x.include = state.include.concat(relationsToinclude).join(",")
    }
    return x
  }

}
