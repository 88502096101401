//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return { dialog: false }
  },
  computed: {
    total () {
      return this.$store.state.frontproperties.total
    },
    showingTotal () {
      return this.numberWithCommas(
        Object.values(this.$store.state.frontproperties.all).length
      )
    },
    formattedTotal () {
      return this.numberWithCommas(this.$store.state.frontproperties.total)
    }
  },
  methods: {
    resetAll () {
      this.$router.push({
        path: this.$route.path,
        query: { map: false }
      })
    }
  }
}
