//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  auth: false,
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js"
    }
  }
  // async fetch() {
  //   const pages = await this.$axios.$get('/api/mock/pages.json');
  //   this.pages = JSON.parse(JSON.stringify(pages))
  // },
  // computed: {
  //   pages: {
  //     get () {
  //       return this.$store.state.pages.data
  //     },
  //     set (value) {
  //       this.$store.commit('pages/set_data', value)
  //     }
  //   }
  // }
}
