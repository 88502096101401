/* eslint-disable */
import { schema, normalize } from "normalizr"

export default ({ ctx, store }, inject) => {
  const relationships = {}
  for (const [key, value] of Object.entries(store.state)) {
    if (value.hasOwnProperty("stub")) {
      relationships[value.type] = new schema.Entity(value.type)
    }
  }

  for (const [key, value] of Object.entries(store.state)) {
    if (value.hasOwnProperty("stub")) {
      if (Object.keys(value.stub).length === 0) {
        relationships[value.type].define({})
      } else {
        let definedRelationships = {}
        Object.keys(value.stub).forEach(stubVal => {
          definedRelationships[stubVal] = relationships[stubVal]
        })
        relationships[value.type].define(definedRelationships)
      }
    }
  }
  inject("relationships", relationships)
}
