//
//
//
//
//
//

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    load: false
  }),
  computed: {
    isLoading: {
      get () {
        return this.loading
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    start () {
      this.isLoading = true
      this.load = true
    },
    finish () {
      this.isLoading = false
      this.load = false
    }
  }
}
