//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      loading: false
    }
  },
  computed: {
    backLabel () {
      if (this.$nuxt.context.from) {
        return "Back"
      }
      return "Search"
    },
    queryOptions () {
      return this.$route.query
    },
    property () {
      if (this.objHas(this.$route.params, "property")) {
        return this.$route.params.property
      }
      return ""
    },
    map () {
      const map = this.queryOptions.map === "true"
      return map
    },
    formattedTotal () {
      return this.numberWithCommas(this.$store.state.frontproperties.total)
    },
    total () {
      return this.$store.state.frontproperties.total
    },
    showingTotal () {
      return this.numberWithCommas(
        Object.values(this.$store.state.frontproperties.all).length
      )
    },
    properties () {
      return this.$store.state.frontproperties.all
    },
    pagination: {
      get () {
        return this.$store.state.frontproperties.pagination
      },
      set (value) {
        this.$store.commit("frontproperties/paginate", value)
      }
    }
  },
  watch: {
    showingTotal () {
      this.loading = false
    }
  },
  methods: {
    getBack () {
      if (this.$nuxt.context.from) {
        this.$router.back()
      } else {
        this.$router.push({
          path: "/properties",
          query: { map: false }
        })
      }
    },
    loadMore () {
      if (Object.values(this.properties).length < this.total) {
        this.loading = true
        setTimeout(() => {
          const pagination = this.cloneObj(this.pagination)
          pagination.page = pagination.page + 1
          this.pagination = pagination
          this.getProperties()
        }, 500)
      }
    },
    getProperties () {
      this.$store.commit("frontproperties/FLUSH_ACTIVE")
      const pagination = this.cloneObj(this.pagination)
      if (this.objHasAndNotEmpty(this.queryOptions, "sort")) {
        pagination.sortBy = this.queryOptions.sort
      }
      this.pagination = pagination
      console.log("SearchTop")
      this.$store.dispatch("frontproperties/list")
    },
    resetAll () {
      this.$router.push({
        path: this.$route.path,
        query: { map: false }
      })
    }
  }
}
